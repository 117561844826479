/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Accordion from "../components/Accordion"

const FaqPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>Frequently asked questions</Heading>
          <Accordion title="Do you do returns?">
            <Text variant="paragraph">
              Yep, as long as your gear has not been worn and has no visible
              marks, you can return your purchase within 14 days of receipt.
            </Text>
            <Text variant="paragraph">
              To request a return, simply email us at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a> and
              we’ll be in contact with you shortly.
            </Text>
            <Text variant="paragraph">
              Please note that as we’re a small business, you’ll need to cover
              the return cost of shipping.
            </Text>
          </Accordion>

          <Accordion title="I ordered the wrong size / colour, can I exchange it?">
            <Text variant="paragraph">
              If you’ve ordered the item than just drop us an email at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a>{" "}
              within 14 days of receipt to request an exchange. In your email,
              please make note of the correct size that you require.
            </Text>
            <Text variant="paragraph">
              As we do limited edition runs, exchanges will be made based on
              availability. If we’ve run out of the colour or size that you’re
              after then we’ll be in touch to organise a credit or refund of
              your choosing.
            </Text>
            <Text variant="paragraph">
              Please note that as we’re a small business, you’ll need to cover
              the cost of shipping.
            </Text>
          </Accordion>

          <Accordion title="I received the wrong item, what do I do?">
            <Text variant="paragraph">
              If you’ve received the wrong item, then please email us at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a> and
              we’ll get in touch ASAP to arrange a replacement. We will cover
              the cost of shipping and walk you through personally the process.
            </Text>
          </Accordion>

          <Accordion title="How long for orders to ship?">
            <Text variant="paragraph">
              All of our orders ship within 1-2 Business Days.
            </Text>
          </Accordion>

          <Accordion title="How do you change or cancel an order?">
            <Text variant="paragraph">
              To change or cancel please email us at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a> and
              we will walk you through the exchange or refund process.
            </Text>
          </Accordion>

          <Accordion title="What forms of payment do you take?">
            <Text variant="paragraph">
              We accept VISA, MasterCard, American Express and Apple Pay.
            </Text>
          </Accordion>

          <Accordion title="Where do you ship?">
            <Text variant="paragraph">
              We ship worldwide using Australia Post.
            </Text>
          </Accordion>

          <Accordion title="Where do I find tracking details?">
            <Text variant="paragraph">
              We’ll email you your tracking number once your items have been
              shipped.
            </Text>
          </Accordion>

          <Accordion title="Where is my order?">
            <Text variant="paragraph">
              If your order hasn’t arrived or you’ve lost your tracking number,
              don’t sweat, just send us an email at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a> and
              we’ll be in touch ASAP to help sort things out.
            </Text>
          </Accordion>

          <Accordion title="Who prints your gear?">
            <Text variant="paragraph">
              The Melbourne based crew at Machine screen printers produce our
              high quality, vibrant designs. With 20+ years of experience,
              they’ve worked with the best including; Nike, Johnny Cupcakes and
              Santa Cruz just to name a few and we trust them to deliver the
              same level of quality for you.
            </Text>
          </Accordion>

          <Accordion title="Is Adam Monster a person, a concept or a business?">
            <Text variant="paragraph">
              All three! The brand is the brainchild of AM’s founder Adam
              Trovarelli.
            </Text>

            <Text variant="paragraph">
              Adam Monster was born to prove the importance of creativity in
              business. At Adam Monster we know that data is not the answer to
              every question and our focus is on solving problems with
              creativity and through storytelling.
            </Text>
          </Accordion>

          <Accordion title="Sizing">
            <Text variant="paragraph">
              Click here to view a <Link to="/size-chart">sizing chart</Link>{" "}
              for our T’s & long sleeve shirts.
            </Text>
          </Accordion>

          <Accordion title="Where is Adam Monster based?">
            <Text variant="paragraph">
              Adam Monster is currently based in Henley Beach, South Australia.
            </Text>
          </Accordion>

          <Accordion title="Do you have any further questions?">
            <Text variant="paragraph">
              If so, then please feel free to drop us an email at{" "}
              <a href="mailto:help@adammonster.com">help@adammonster.com</a>
            </Text>
          </Accordion>
        </Box>
      </Box>
    </Layout>
  )
}

export default FaqPage
