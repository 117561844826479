import React, { useState } from "react"
import { Text, Flex, Box } from "theme-ui"

const Accordion = ({ title, children, onToggle }) => {
  const [visibility, setVisibility] = useState(false)
  const handleClick = () => {
    setVisibility(!visibility)
    if (onToggle) onToggle(!visibility)
  }

  return (
    <Box sx={{ borderBottom: "1px solid #d8d8d8" }}>
      <Flex
        onClick={handleClick}
        sx={{ cursor: "pointer", alignItems: "center", pt: 3, pb: 2 }}
      >
        <Text>{title}</Text>
        <Box sx={{ marginLeft: "auto" }}>
          <svg
            width="25px"
            height="25px"
            viewport="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#081B26" fillRule="evenodd">
              {!visibility && <path d="M11 1.121L14 0v22.879L11 24z" />}
              <path d="M24.5 10.5l-1.24 3H.5l1.24-3z" />
            </g>
          </svg>
        </Box>
      </Flex>
      {visibility ? <>{children}</> : null}
    </Box>
  )
}

export default Accordion

// const AccordionItem = styled("div")`
//   border-bottom: 1px solid #d8d8d8;
// `

// const Toggle = styled()`
//   margin-left: auto;
//   width: 25px;
//   height: 24px;

//   svg {
//     width: 25px;
//     height: 24px;
//   }
// `
